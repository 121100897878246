.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--color-text-secondary);

    h2 {
        font-weight: normal;
    }

    .description {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        font-weight: bold;
        align-items: center;
    }

    hr {
        width: 100%;
        border: 1px solid var(--color-secondary);
        margin: 15px 0;
    }

    .cost,
    .taxes,
    .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding: 0;

        label {
            flex: 1;
            text-align: left;
            font-size: var(--font-purchase);
        }

        p {
            height: 50px;
            margin: 0;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: var(--font-costs);
        }
    }

    .total p {
        font-weight: bold;
        font-size: var(--font-price);
    }

    .paymentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 1em 0;

        .payment {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, max-content));
            grid-gap: 0.5em 1em;
            justify-content: center;
            padding: initial;
            width: 100%;

            .paymentCheckbox {
                width: 160px;

                input[type='checkbox'] {
                    opacity: 0;
                    cursor: pointer;
                    position: absolute;
                    width: 160px;
                    height: 48px;

                    /* &:hover + svg {
                        border: 2px solid var(--color-primary);
                    } */

                    &:checked {
                        border: 2px solid var(--color-primary);
                    }
                }

                svg {
                    border: 2px solid transparent;
                    border-radius: 5px;
                }

                .paymentMethodButton {
                    width: 160px;
                    height: 48px;
                    border-radius: 5px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                    border: 2px solid transparent;
                    background-color: transparent;

                    padding: 0;
                
                    &:hover {
                        border: 2px solid var(--color-primary);
                    }
                
                    &.active {
                        border: 2px solid var(--color-primary);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .buttonBorder {
                    border: 2px solid var(--color-primary);
                }
            }
        }
    }

    .terms {
        margin: 15px 0;
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 1em;

        Input {
            margin-top: 5px;
        }
    }
}
