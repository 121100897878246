@import '@/styles/tools/responsive';

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;

    .type {
        width: 100%;
    }

    .wordsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--words-width);

        /* @include breakpoint(smallDesktop) {
            width: var(--words-width-small-desktop);
        } */

        /* @include breakpoint(tablet) {
            width: var(--words-width-tablet);
        } */

        @include breakpoint(smallTablet) {
            width: var(--words-width-small-tablet);
        }

        @include breakpoint(mobile) {
            width: var(--words-width-mobile);
        }

        .words {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(18em, max-content));
            grid-gap: 0.75em 1.75em;
            justify-content: center;
            padding: initial;
            width: 100%;

            /* @include breakpoint(smallDesktop) {
                grid-template-columns: repeat(auto-fit, minmax(16em, max-content));
            }

            @include breakpoint(lessTablet) {
                grid-template-columns: repeat(auto-fit, minmax(18em, max-content));
            } */

            @include breakpoint(tablet) {
                grid-template-columns: repeat(auto-fit, minmax(16em, max-content));
            }

            /* @include breakpoint(smallTablet) {
                grid-template-columns: repeat(auto-fit, minmax(15em, max-content));
                grid-gap: 0.5em 0.5em;
            } */

            .section {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: 1em;

                width: 18em;

                /* @include breakpoint(smallDesktop) {
                    width: 16em;
                }

                @include breakpoint(lessTablet) {
                    width: 18em;
                } */

                @include breakpoint(tablet) {
                    width: 16em;
                }

                /* @include breakpoint(smallTablet) {
                    width: 15em;
                    gap: 0.7em;
                } */

                @include breakpoint(mobile) {
                    width: 100%;
                }

                Input {
                    margin-top: 5px;
                }

                .label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    min-width: 5.1em;
                }

                .disabledSectionLabel {
                    text-decoration: line-through;
                    opacity: 50%;
                }

                .inputNumbers {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 0.2rem;

                    input {
                        width: 100%;
                        padding: 23px 0px;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}
