.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 10px;

    Input {
        width: 100%;
    }
}
