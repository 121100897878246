.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
    font-size: var(--font-content);
    color: var(--color-text);

    .question {
        grid-column: span 4;

        textarea {
            width: 100%;
        }
    }

    .text {
        grid-column: span 4;
        display: grid;
        gap: 0.5rem;
        padding-top: 1.5rem;
    }

    .table {
        grid-column: span 4;
        display: grid;
        gap: 0.5rem;
        padding: 0.5rem 0;
    }

    .row {
        display: grid;
        grid-template-columns: 30px 4rem 1fr 30px;
        gap: 0.5rem;
    }

    .clearButton,
    .checkboxContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .answerId {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        Input {
            width: 100%;
            padding-left: 0.5rem;
            padding-right: 0;
            text-align: center;
        }
    }

    .answerText {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;

        Input {
            width: 100%;
            padding-left: 1.5rem;
            padding-right: 0;
        }
    }

    .addButton {
        grid-column: span 4;
        display: flex;
    }
}
