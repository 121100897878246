@import '@/styles/tools/responsive';

.container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: auto;
    flex-grow: 1;
    background-color: var(--color-primary);
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--home-content-width);
    gap: 1rem 2rem;

    color: var(--color-white);

    @include breakpoint(mobile) {
        gap: 0.5rem 0;
    }
}

.imageTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    padding: 1.5rem 0;

    img {
        width: 100%;

        @include breakpoint(mobile) {
            width: 50%;
        }
    }
}

.titleRight {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 2;
}

h1 {
    font-size: var(--font-title-home);
    font-weight: bold;
    margin: 0;

    @include breakpoint(smallTablet) {
        font-size: var(--font-title-home-tablet);
    }

    @include breakpoint(mobile) {
        font-size: var(--font-title-home-mobile);
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding: 0 0 2.5rem 0;
    flex: 1.5;
    width: 100%;

    @include breakpoint(tablet) {
        flex: none;
    }

    .description {
        font-size: var(--font-description-home);

        @include breakpoint(smallTablet) {
            font-size: var(--font-description-home-tablet);
        }

        @include breakpoint(mobile) {
            font-size: var(--font-description-home-mobile);
        }
    }

    .bulletPoints {
        ul {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            padding: 0;
            font-weight: bold;

            @include breakpoint(smallTablet) {
                flex-direction: column;
                flex-wrap: nowrap;
                font-size: var(--font-bullet-points-home-tablet);
            }

            @include breakpoint(mobile) {
                font-size: var(--font-bullet-points-home-mobile);
            }

            li {
                width: 50%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                padding: 0.25rem 1rem 0.25rem 0;

                @include breakpoint(smallTablet) {
                    width: 100%;
                    padding: 0.25rem 0;
                }

                svg {
                    margin-right: 0.5rem;
                    flex-shrink: 0;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        align-items: center;
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;

            Button {
                width: 100%;
            }
        }
    }
}
