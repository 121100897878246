@import '@/styles/tools/responsive';

.container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--form-content-width);
    gap: 2rem;
    padding: 2rem 0;

    color: var(--color-text);

    @include breakpoint(smallTablet) {
        width: var(--form-content-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--form-content-width-mobile);
    }
}

.image,
.stepper,
.title,
.form,
.navigationButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.image {
    width: var(--form-image-width);

    @include breakpoint(smallTablet) {
        width: var(--form-image-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--form-image-width-mobile);
    }

    img {
        width: 100%;
    }
}

.stepper {
    width: var(--form-stepper-width);

    @include breakpoint(smallTablet) {
        width: var(--form-stepper-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--form-stepper-width-mobile);
    }
}

.title {
    h1 {
        text-align: center;
        font-size: var(--font-title-form);
        font-weight: bold;
        margin: 0;

        @include breakpoint(smallTablet) {
            font-size: var(--font-title-form-tablet);
        }

        @include breakpoint(mobile) {
            font-size: var(--font-title-form-mobile);
        }
    }
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    .leftButton {
        // max-width: 50%;
        display: flex;
        flex: 1;
        justify-content: flex-start;
    }

    .rightButton {
        // max-width: 50%;
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
}
