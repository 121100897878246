.container {
    font-size: var(--font-content);
    color: var(--color-text);
    margin: 10px 0;
    padding: 0;
    width: 100%;

    textarea {
        width: 100%;
    }

    .content {
        font-size: var(--font-content);
        color: var(--color-text);
        margin: 10px 0;
        flex: 1;
        gap: 1rem;
    }

    .checkboxContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        margin: 1.5rem;

        .checkboxOption {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;
        }
    }

    .uploadArea {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        border: 1px solid var(--color-secondary);
        border-radius: var(--border-radius-input);
        padding: 0;
        font-size: var(--font-content);
        color: var(--color-text-secondary);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        // placeholder
        &::placeholder {
            color: var(--color-placeholder);
            font-size: var(--font-placeholder);
        }

        // with content
        &:not(:placeholder-shown) {
            border: 1px solid var(--color-primary);
        }

        // hover
        &:hover {
            border: 1px solid var(--color-primary);
        }

        // focus
        &:focus {
            outline: none;
            border: 1px solid var(--color-primary);
        }

        .uploadImage {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            width: 100%;
        }

        .uploadText {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            gap: 0.5rem;
            max-width: 100%;
            text-align: center;
        }
    }
}
