@import '@/styles/tools/responsive';

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--header-height);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-level-5);
    background-color: var(--color-primary);
}

.logo {
    height: calc(var(--header-height) - 1.3rem);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-25vw);

    @include breakpoint(mobile) {
        transform: translateX(0);
    }
}
