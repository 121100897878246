@import '@/styles/tools/responsive';

// remove arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

.inputWrapper {
    position: relative; // To position the error message
    width: 100%;
}

.inputWrapperError {
    position: relative; // To position the error message
    width: 100%;
    padding-bottom: var(--font-small);
}

.input {
    border: 1px solid var(--color-secondary);
    border-radius: var(--border-radius-input);
    padding: 23px 34px;
    font-size: var(--font-content);
    color: var(--color-text-secondary);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // placeholder
    &::placeholder {
        color: var(--color-placeholder);
        font-size: var(--font-placeholder);
    }

    // with content
    &:not(:placeholder-shown) {
        border: 1px solid var(--color-primary);
    }
}

.inputHoverAndFocus {
    // hover
    &:hover {
        border: 1px solid var(--color-primary);
    }

    // focus
    &:focus {
        outline: none;
        border: 1px solid var(--color-primary);
    }
}

input[type='checkbox'] {
    margin-right: 0;
    background-color: var(--color-white);
    align-items: center;
    appearance: none;
    border: 1px solid var(--color-secondary);
    border-radius: 8px;
    transform: translateY(-0.15em);
    cursor: pointer;
    min-width: 24px;
    min-height: 24px;

    &:hover {
        border: 1px solid var(--color-primary);
    }

    &:checked {
        border: 1px solid var(--color-primary);
        background-color: var(--color-primary);
        position: relative;
        // simple checkmark
        &::after {
            content: '';
            position: absolute;
            left: 0.3em;
            // top: 0.75em;
            width: 0.5em;
            height: 0.75em;
            border: solid var(--color-white);
            border-width: 0 0.2em 0.2em 0;
            transform: rotate(45deg);
        }
    }
}

.viewHideButton {
    position: absolute;
    right: 0px;
    top: 50%;
    height: 100%;
    width: var(--visible-button-width);
    transform: translateY(-50%);
    background: var(--color-secondary);
    border-radius: var(--border-radius-input);
    border: none;
    cursor: pointer;
    color: var(--color-text-primary);

    @include breakpoint(smallTablet) {
        width: var(--visible-button-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--visible-button-width-mobile);
    }
}

.error {
    .input {
        border-color: #cc0000; // Change border color to indicate error
    }
}

.errorText {
    color: #cc0000;
    font-size: var(--font-small);
    position: absolute;
    top: 4em;
    left: 10px;
}
