@import '@/styles/tools/responsive';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    flex-grow: 1;
    color: var(--color-white);
    background-color: var(--color-primary);
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--home-content-width);
    gap: 1rem 2rem;

    @include breakpoint(mobile) {
        gap: 0.5rem 0;
    }
}

.tutorialBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--home-content-width);
    overflow: hidden; // Agrega esta línea para ocultar el contenido que exceda los límites del bloque
}

.introduction {
    font-size: var(--font-introduction-home);

    @include breakpoint(smallTablet) {
        font-size: var(--font-introduction-home-tablet);
    }

    @include breakpoint(mobile) {
        font-size: var(--font-introduction-home-mobile);
    }
}

.bulletPoints {
    ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        padding: 0;
        // font-weight: bold;

        @include breakpoint(smallTablet) {
            flex-direction: column;
            flex-wrap: nowrap;
            font-size: var(--font-bullet-points-home-tablet);
        }

        @include breakpoint(mobile) {
            font-size: var(--font-bullet-points-home-mobile);
        }

        li {
            width: 50%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 0.25rem 1rem 0.25rem 0;

            @include breakpoint(smallTablet) {
                width: 100%;
                padding: 0.25rem 0;
            }

            svg {
                margin-right: 0.5rem;
                flex-shrink: 0;
            }
        }
    }
}

.buttonsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include breakpoint(smallTablet) {
        flex-direction: column;
    }

    .button {
        width: 100%;

        Button {
            width: 100%;
            height: 5rem;
        }
    }

    .tutorialButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        Button {
            width: 50%;
            height: 2.75rem;
        }
    }
}

.buttonBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;

    .featureButton {
        width: 100%;
        max-width: 20rem;

        Button {
            width: 100%;
            height: 5rem;
        }
    }

    .tutorialButton {
        display: flex;
        justify-content: right;
        align-items: center;
        width: 100%;
        max-width: 20rem;

        Button {
            width: 50%;
            height: 2.75rem;
        }
    }
}

.description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--home-content-width);
    flex: 1.5;
    font-size: var(--font-description-home);
    text-align: justify;
    padding: 2rem;

    @include breakpoint(smallTablet) {
        font-size: var(--font-description-home-tablet);
    }

    @include breakpoint(mobile) {
        font-size: var(--font-description-home-mobile);
    }
}

.imageTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    padding: 1.5rem 0;

    img {
        width: 100%;

        @include breakpoint(mobile) {
            width: 60%;
        }
    }
}

.title {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    flex: 2;

    @include breakpoint(tablet) {
        justify-content: center;
    }

    @include breakpoint(mobile) {
        text-align: center;
    }
}

h1 {
    font-size: var(--font-title-home);
    font-weight: bold;
    margin: 0;

    @include breakpoint(smallTablet) {
        font-size: var(--font-title-home-tablet);
    }

    @include breakpoint(mobile) {
        font-size: var(--font-title-home-mobile);
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding: 0 0 2.5rem 0;
    flex: 1.5;
    width: 100%;

    @include breakpoint(tablet) {
        flex: none;
        gap: 1rem;
    }
}

.imageContainer {
    overflow: hidden; /* Asegura que cualquier contenido adicional se oculte */
    transition: height 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Transición de 0.5 segundos para la altura con una curva de bezier para un efecto más suave */
    height: 0; /* Inicialmente, la altura es 0 */
}

.imageContainer.loaded {
    height: 100%; /* Cuando la imagen se carga, la altura se expandirá al 100%, permitiendo que el contenido se muestre */
}

.tutorialImage {
    width: 100%;
    opacity: 0; /* Inicialmente, la imagen estará oculta */
    transition: opacity 0.5s ease-in-out; /* Transición suave de opacidad durante 0.5 segundos */
}

.tutorialImage.loaded {
    opacity: 1; /* Cuando la imagen se carga, se mostrará con opacidad 1 */
}
