@import '@/styles/tools/responsive';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    flex-grow: 1;
    // background-color: var(--color-secondary);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--error-content-width);
    gap: 4em;
    padding: 2em 0;

    @include breakpoint(smallTablet) {
        width: var(--error-content-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--error-content-width-mobile);
    }
}

.image,
.text,
.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.image {
    width: var(--error-image-width);
    height: auto;

    @include breakpoint(smallTablet) {
        width: var(--error-image-width-tablet);
    }

    @include breakpoint(mobile) {
        width: var(--error-image-width-mobile);
    }

    img {
        width: 100%;
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h1 {
        text-align: center;
        color: var(--color-text);
        font-size: var(--font-title-error);

        @include breakpoint(smallTablet) {
            font-size: var(--font-title-error-tablet);
        }

        @include breakpoint(mobile) {
            font-size: var(--font-title-error-mobile);
        }
    }
}