.container {
    font-size: var(--font-content);
    color: var(--color-text);
    margin: 10px 0;
    padding: 0;
    width: 100%;
}
.content {
    margin: 0;
    padding: 0;
    width: 100%;

    Select, input, textarea {
        width: 100%;
    }

    .type, .title, .overview {
        font-size: var(--font-content);
        color: var(--color-text);
        margin: 10px 0;
        flex: 1;
    }
}
