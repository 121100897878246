@import '@/styles/tools/responsive';

.container {
    position: relative;
    padding-top: var(--header-height);
    padding-bottom: var(--footer-height);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    background-color: var(--bg-main);

    @include breakpoint(smallTablet) {
        padding-bottom: var(--footer-height-tablet);
    }

    @include breakpoint(mobile) {
        padding-bottom: var(--footer-height-mobile);
    }
}
