.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        gap: 10px;

        Input {
            width: 100%;
        }
    }

    .purchaseInfo {
        width: 100%;
    }

    .discount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

    }

    .terms {
        margin: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;

        Input {
            margin-top: 5px;
        }
    }
}
