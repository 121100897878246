@import '@/styles/tools/responsive';

.footerPrimary {
    background-color: var(--color-primary);

    @include breakpoint(smallTablet) {
        background-color: var(--color-secondary);
    }
}

.footerSecondary {
    background-color: var(--color-secondary);

    @include breakpoint(smallTablet) {
        background-color: var(--color-primary);
    }
}

.footerTextPrimary {
    color: var(--color-primary);

    @include breakpoint(smallTablet) {
        color: var(--color-white);
    }
}

.footerTextSecondary {
    color: var(--color-white);

    @include breakpoint(smallTablet) {
        color: var(--color-primary);
    }
}

.container {
    position: fixed;
    bottom: 0;
    height: var(--footer-height);
    width: 100%;
    z-index: 999;

    display: flex;
    flex-direction: row;
    align-items: center;

    @include breakpoint(lessTablet) {
        height: var(--footer-height-small-desktop);
    }

    @include breakpoint(smallTablet) {
        height: var(--footer-height-tablet);
        flex-wrap: wrap;
    }

    @include breakpoint(mobile) {
        height: var(--footer-height-mobile);
    }
}

.contact,
.links,
.rights {
    font-size: var(--font-footer);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(lessTablet) {
        font-size: var(--font-footer-small-desktop);
    }

    @include breakpoint(smallTablet) {
        font-size: var(--font-footer-tablet);
    }

    @include breakpoint(mobile) {
        font-size: var(--font-footer-mobile);
    }
}

.contact {
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;

    @include breakpoint(smallTablet) {
        min-width: 50%;
        order: 1;
        flex: 1 0 50%;
    }

    .contactInsideContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.3rem;
    }
}

.links {
    align-items: center;
    justify-content: center;

    span {
        margin: 0 10px;
    }

    @include breakpoint(smallTablet) {
        min-width: 50%;
        order: 2;
        flex-direction: column;
        align-items: flex-end; // center ?
        flex: 1 0 50%;
        padding-right: 20px;
        
        a {
            text-align: left;
        }
    }

    .linksInsideContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 0.3rem;
        
        @include breakpoint(smallTablet) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.rights {
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;

    @include breakpoint(smallTablet) {
        width: 100%;
        order: 3;
        flex: 0 1 100%;
        align-items: center;
        padding-right: 0;
    }

    .rightsInsideContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 0.3rem;

        @include breakpoint(smallTablet) {
            align-items: center;
        }
    }
}

a {
    // color: var(--color-primary);
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}
