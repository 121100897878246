.textArea {
    border: 1px solid var(--color-secondary);
    border-radius: var(--border-radius-input);
    padding: 23px 34px;
    font-size: var(--font-content);
    color: var(--color-text-secondary);

    // placeholder
    &::placeholder {
        color: var(--color-placeholder);
        font-size: var(--font-placeholder);
    }

    // focus
    &:focus {
        outline: none;
        border: 1px solid var(--color-primary);
    }

    // hover
    &:hover {
        border: 1px solid var(--color-primary);
    }

    // with content
    &:not(:placeholder-shown) {
        border: 1px solid var(--color-primary);
    }
}

textarea {
    resize: vertical;
    max-width: 100%;
}

::-webkit-resizer {
    display: none;
}
